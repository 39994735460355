class HeaderUser extends Component {

    constructor(el) {
        super(el);

        const usernavOpener = this.$find('[data-usernav-opener]');
        const dropdown = this.$find('[data-dropdown]');

        // Generická funkce pro ověření, zda jsme kliknuli mimo element
        // Funkce musí být neanonymní, jinak ji nelze odregistovat z event stacku
        // Pro funkčnost předpokládá, že v this má kontext {$el, onUp(), onDown(), [handler]}
        const onClickOutside = (function (e) {
            if (!this.$el.contains(e.target)) {
                this.onDown()
                document.removeEventListener('click', this.handler);
            }
        })

        // Je třeba mít jistotu, že už handler neexistuje.
        // Pokud existuje, je třeba jej odstranit. Jinak pokud bychom klikali na spouštěcí event, registrovaly by se nám
        // stále nové eventy.
        const onClickOutsideFactory = function (binding) {
            binding.onUp()
            if (binding.handler) document.removeEventListener('click', binding.handler)
            binding.handler = onClickOutside.bind(binding)
            return binding.handler // vrátíme handler, referenci uchováme, abychom mohli odregistrovat z event stacku
        }

        if (usernavOpener) {
            // vytvoříme handler "staticky", tj. aby byl nezávislý na scope spouštěcím eventu
            const onClickOutsideUsernav = {
                $el: this.$el,
                onUp: () => {
                    dropdown.classList.toggle('is-opened');
                    usernavOpener.classList.toggle('is-active');
                },
                onDown: () => {
                    dropdown.classList.remove('is-opened');
                    usernavOpener.classList.remove('is-active');
                }
            }

            // spouštěcí event
            usernavOpener.addEventListener('click', () => {
                document.addEventListener('click', onClickOutsideFactory(onClickOutsideUsernav))
            });
        }
    }

}